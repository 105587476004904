import { useEffect, FormEventHandler, useState } from 'react'
import Checkbox from '@/components/Checkbox'
import GuestLayout from '@/Layouts/GuestLayout'
import InputError from '@/components/InputError'
import InputLabel from '@/components/InputLabel'
import PrimaryButton from '@/components/PrimaryButton'
import TextInput from '@/components/TextInput'
import { Head, useForm } from '@inertiajs/react'
import { Input, Spinner } from '@nextui-org/react'
import { FaRegEye } from 'react-icons/fa'
import { FaRegEyeSlash } from 'react-icons/fa'
import { usePage } from '@inertiajs/react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Divider,
    Link,
    Image,
    Button
} from '@nextui-org/react'
import AppHead from '@/Layouts/app-head'

const SITE_NAME = 'Kecs'

export default function Login ({
    status,
    canResetPassword
}: {
    status?: string
    canResetPassword: boolean
}) {
    const [isVisible, setIsVisible] = useState(false)
    const toggleVisibility = () => setIsVisible(!isVisible)
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false
    })

    useEffect(() => {
        return () => {
            reset('password')
        }
    }, [])

    const submit: FormEventHandler = e => {
        e.preventDefault()

        post(route('login'))
    }
    const { auth, appName } = usePage().props

    return (
        <GuestLayout>
            <AppHead title='Log in' />

            {status && (
                <div className='mb-4 font-medium text-sm text-success-600'>
                    {status}
                </div>
            )}
            <Card
                shadow='lg'
                className='max-w-[400px]x w-full bg-background-800'
            >
                <CardHeader className='flex gap-3'>
                    <div className='flex flex-col'>
                        <p className='text-md'>Login</p>
                        <p className='text-small text-default-500'>
                            {appName?.toString()}
                        </p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody>
                    <div className='w-full py-10 '>
                        <form
                            className='grid grid-cols-6 gap-6'
                            onSubmit={submit}
                        >
                            <div className='col-span-6 sm:col-span-6 w-full'>
                                <Input
                                    isRequired
                                    label='Email'
                                    variant='bordered'
                                    placeholder='Enter your email'
                                    color='secondary'
                                    id='email'
                                    name='email'
                                    type={'text'}
                                    value={data.email}
                                    onChange={e =>
                                        setData('email', e.target.value)
                                    }
                                    autoComplete='username'
                                    // classNames={{
                                    //     label: 'text-black/90',
                                    //     input: [
                                    //         '!bg-transparent',
                                    //         'text-black/90 ',
                                    //         'placeholder:text-black',
                                    //         'focus-within:bg-transparent'
                                    //     ],
                                    //     inputWrapper:
                                    //         'bg-foreground text-black '
                                    // }}
                                />
                                <InputError
                                    message={errors.email}
                                    className='mt-2'
                                />
                            </div>

                            <div className={'col-span-6 sm:col-span-6 w-full'}>
                                <Input
                                    isRequired
                                    label='Password'
                                    variant='bordered'
                                    placeholder='Enter your password'
                                    color='secondary'
                                    id='password'
                                    value={data.password}
                                    className='mt-1 block w-full'
                                    autoComplete='current-password'
                                    onChange={e =>
                                        setData('password', e.target.value)
                                    }
                                    // classNames={{
                                    //     label: 'text-black/90x text-white',
                                    //     input: [
                                    //         '!bg-transparentx',
                                    //         'text-black/90 ',
                                    //         'placeholder:text-blackx',
                                    //         'focus-within:bg-transparent'
                                    //     ],
                                    //     inputWrapper:
                                    //         'bg-foreground text-blackx '
                                    // }}
                                    endContent={
                                        <button
                                            className='focus:outline-none'
                                            type='button'
                                            onClick={toggleVisibility}
                                        >
                                            {isVisible ? (
                                                <FaRegEye className='text-2xl text-default-400 pointer-events-none' />
                                            ) : (
                                                <FaRegEyeSlash className='text-2xl text-default-400 pointer-events-none' />
                                            )}
                                        </button>
                                    }
                                    type={isVisible ? 'text' : 'password'}
                                />
                                <InputError
                                    message={errors.password}
                                    className='mt-2'
                                />
                            </div>
                            <div className=' col-span-6 sm:col-span-6 w-full'>
                                <label className='flex items-center'>
                                    <Checkbox
                                        name='remember'
                                        checked={data.remember}
                                        onChange={e =>
                                            setData(
                                                'remember',
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <span className='ms-2 text-sm text-foreground-600'>
                                        Remember me
                                    </span>
                                </label>
                            </div>

                            <div className='col-span-6 sm:col-span-6 w-full flex gap-4'>
                                <Button
                                    type='submit'
                                    disabled={processing}
                                    isLoading={processing}
                                    color='primary'
                                    className='px-12 '
                                    spinner={
                                        <Spinner
                                            color='warning'
                                            labelColor='warning'
                                        />
                                    }
                                    spinnerPlacement='end'
                                    fullWidth
                                >
                                    <div className='flex gap-4 items-center'>
                                        <span>Log in</span>{' '}
                                    </div>
                                </Button>
                            </div>
                            <div className='col-span-6 sm:col-span-6 w-full'>
                                {canResetPassword && (
                                    <Link
                                        href={route('password.request')}
                                        className='underline text-sm text-secondary-600 hover:text-secondary-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                                    >
                                        Forgot your password?
                                    </Link>
                                )}
                            </div>
                        </form>
                    </div>
                </CardBody>
                <Divider />
                <CardFooter>
                    {true && (
                        <p className='mt-4 text-sm text-foreground/70 sm:mt-0'>
                            {"Don't have an account? "}

                            <Link
                                href={route('register')}
                                className='underline text-sm text-secondary-600 hover:text-secondary-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                            >
                                Create one now
                            </Link>
                        </p>
                    )}
                </CardFooter>
            </Card>
        </GuestLayout>
    )
}
